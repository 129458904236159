import type { SyntheticEvent } from 'react';
import React, { useMemo } from 'react';

import { Badge, Typography } from '@sravni/react-design-system';
import { useBoolean } from '@sravni/react-utils';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import type { AffSub4 } from '@src/@types/microcredits';
import { AdvertisingButton } from '@src/components/AdvertisingButton';
import { CurtainPopup } from '@src/components/CurtainPopup';
import { Content } from '@src/components/details/components/DetailsCard/components/Content';
import { Header } from '@src/components/details/components/DetailsCard/components/Header';
import { getBadgeList } from '@src/components/details/components/DetailsCard/utils';
import { AdditionalConditions } from '@src/components/ProductList/components/CardV2/components/AdditionalConditions';
import { NoAdditionalConditions } from '@src/components/ProductList/components/CardV2/components/NoAdditionalConditions';
import { getAdditionalConditionsCardText } from '@src/components/ProductList/components/CardV2/utils/getAdditionalConditionsCardText';
import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';
import { sendAdditionalConditionsClickEvent, sendRepaymentConditionsClickEvent } from '@src/helpers/analyticsEvents';
import { useCardData } from '@src/hooks/useCardData';
import { RepaymentConditions } from 'src/components/details/components/RepaymentConditions';

import styles from './styles.module.scss';

type Props = {
  amount: number;
  term: number;
  affSub4: AffSub4;
  analytics: IAnalyticsItem;
  card: IClientMicrocredit;
};

const { Text } = Typography;

export const DetailsCard = ({ term, amount, affSub4, analytics, card }: Props) => {
  const [isAdditionalConditionsOpen, setAdditionalConditionsOpen] = useBoolean(false);
  const [isRepaymentConditionsOpen, setRepaymentConditionsOpen] = useBoolean(false);

  const formattedCard = useCardData(card?.rates?.[Currency.RUB]);

  const hasAdditionalConditions = card.additionalServices?.exist;

  const badges = useMemo(
    () => getBadgeList(hasAdditionalConditions, card?.benefits),
    [card?.benefits, hasAdditionalConditions],
  );

  const additionalConditionsBadge = getAdditionalConditionsCardText(card.additionalServices);

  const source = `search|position_${card.advertising?.position}`;

  const eventLabel = `${card.organization.name}|${card.name}|${card.advertising?.offerId}|${source}`;

  const handleAdditionalConditionsTooltipClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAdditionalConditionsOpen.on();

    const eventActionValue = `${additionalConditionsBadge}|Открытие из Карточки доп. услуг`;
    sendAdditionalConditionsClickEvent(eventLabel, eventActionValue);
  };

  const handleCompleteButtonClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAdditionalConditionsOpen.off();

    const eventActionValue = `${additionalConditionsBadge}|Кнопка понятно`;
    sendAdditionalConditionsClickEvent(eventLabel, eventActionValue);
  };

  const handleRepaymentConditionsTooltipClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setRepaymentConditionsOpen.on();

    const eventActionValue = 'Открытие';
    sendRepaymentConditionsClickEvent(eventLabel, eventActionValue);
  };

  const handleRepaymentConditionsCompleteButtonClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setRepaymentConditionsOpen.off();

    const eventActionValue = 'Кнопка понятно';
    sendRepaymentConditionsClickEvent(eventLabel, eventActionValue);
  };

  return (
    <>
      <div className={styles.container}>
        {Boolean(badges.length) && (
          <div className={styles.badgeList}>
            {badges.map((badge) => (
              <Badge key={badge} color="blue" variant="primary" text={badge} />
            ))}
          </div>
        )}

        <Header
          amount={amount}
          term={term}
          rateMax={formattedCard.rateMax}
          isRateRange={formattedCard.isRateRange}
          cardName={card.name}
          organization={card.organization}
          isBankPartner={card.isBankPartner}
          advertising={card.advertising}
        />

        <Content
          amount={amount}
          term={term}
          card={card}
          rateMax={formattedCard.rateMax}
          rateRange={formattedCard.rateRange}
          isRateRange={formattedCard.isRateRange}
          onAdditionalConditionsTooltipClick={handleAdditionalConditionsTooltipClick}
          onRepaymentConditionsTooltipClick={handleRepaymentConditionsTooltipClick}
        />

        <div>
          <AdvertisingButton
            source={source}
            advSub={card.advertising?.advSub}
            analytics={analytics}
            affSub4={affSub4}
            size={60}
          />
          <Text className="h-color-D40 h-mt-8 h-text-center" size={12}>
            Расчет является предварительным, одобренная сумма, срок и ставка могут отличаться
          </Text>
        </div>
      </div>

      <CurtainPopup
        visible={isAdditionalConditionsOpen}
        onClose={setAdditionalConditionsOpen.off}
        title="Дополнительные услуги"
        onComplete={handleCompleteButtonClick}
      >
        {hasAdditionalConditions ? (
          <AdditionalConditions additionalServices={card.additionalServices} />
        ) : (
          <NoAdditionalConditions />
        )}
      </CurtainPopup>

      <CurtainPopup
        visible={isRepaymentConditionsOpen}
        onClose={setRepaymentConditionsOpen.off}
        title="Платное погашение"
        onComplete={handleRepaymentConditionsCompleteButtonClick}
      >
        <RepaymentConditions text={card?.repaymentConditions?.comment} />
      </CurtainPopup>
    </>
  );
};
